@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import  'material-icons/iconfont/material-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* custom scrollbar */
::-webkit-scrollbar {
    width: 18px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #166534;
    padding: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #9ca3af;
    border: 6px solid transparent;
    background-clip: content-box;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #2f7c4d;
  }
  
  .icon-home{
    width: 3.2em;
    padding: 0 1em 0 1em;
  }
  .cls-1{
    fill:#166534;
  }
